<template>
    <div class="aichat">
        <div ref="chatlist" class="chatList">
            <div v-for="item in msgList" :key="item">
                <!-- 系统消息 -->
                <div v-if="item.type == 0" class="line">
                    <span style="font-weight: 550;">{{ item.time }}</span><br>
                    <div class="row">
                        <i class="el-icon-sunrise" style="font-size: 30px; margin: 8px 10px 0px 3px"></i> 
                        <div class="msg-content2">
                            <div v-html="item.msg"></div>
                        </div>
                    </div>
                </div>
                <!-- 用户消息 -->
                <div v-if="item.type == 1" class="line">
                    <span style="font-weight: 550;">{{ item.time }}</span><br>
                    <div class="row">
                        <i class="el-icon-user" style="font-size: 30px; margin: 8px 10px 0px 3px"></i>
                        <div class="msg-content1">
                            <div v-html="item.msg"></div>
                        </div>
                    </div>
                </div> 
            </div>
            
            <div v-if="msgList.length < 1">
                <i class="el-icon-sunrise" style="margin-right: 6px;"></i>Hi，您好，这里是Ai助手，请输入您的问题点击发送即可.
            </div>
        </div>
        <div class="input">
            <div class="form-row">
                <el-input type="textarea" :disabled="sendLoading" v-model="message" placeholder="请输入您的对话内容"  :autosize="{ minRows: 6, maxRows: 6}" style="width: 1100px; margin-right: 8px;"></el-input>   
                <div class="column">
                    <el-button class="btn" @click="clear" :disabled="sendLoading" type="default" style="height: 40px; margin-bottom: 10px;">重置</el-button>
                    <el-button class="btn" @click="send" :loading="sendLoading" type="primary">发送</el-button>  
                </div>
            </div> 
        </div>
    </div>
</template>
<script>
import { chat } from '@/services/ai.service';
import { getSysValue } from '../AppUtil';

export default {
    name: 'AiChatDialog',
    comments: {
    },
    data(){
        return {
            message: '',
            msgList: [],
            sendLoading : false,
            id: undefined
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){ 
        },
        send(){
            let msg = this.message;
            let time = getSysValue('当前时间');
            this.msgList.push({
                type: 1,
                msg: msg,
                time: time
            });
            this.message = '';
            this.sendLoading = true;
            chat({
                id: this.id,
                message: msg
            }).then(res => {
                this.id = res.id;
                this.sendLoading = false;
                this.msgList.push({
                    type: 0,
                    msg: res.message,
                    time: time
                });
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            }, err => {
                this.sendLoading = false;
                this.msgList.push({
                    type: 2,
                    msg: err ? err : '请求发生错误',
                    time: time
                });
            })
        },
        scrollToBottom() {
            // 获取 DOM 元素
            const chatList = this.$refs.chatlist;
            // 滚动到最底部
            chatList.scrollTop = chatList.scrollHeight;
        },
        clear(){
            this.msgList = [];
            this.id = undefined;
        }
    }
}
</script>
<style lang="scss" scoped>
.aichat {
    width: 1147px;
    height: 100%;
    padding: 10px 0;
}
.chatList {
    width: 1147px;
    height: 500px; 
    max-height: 500px; 
    overflow-y: auto; 
    margin: 10px 0 10px 0; 
    background: #ffffff; 
    box-shadow:2px 2px 5px #409EFF;
    border-radius: 4px; 
    padding: 6px;

    .line {
        margin-bottom: 10px;
    }
    
}
.form-row {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .btn {
        margin-left: 6px;
        height: 55px;
    }
}
.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.msg-content1 {
    background-color: #409EFF;
    color: #ffffff;
    border-radius: 4px;
    padding: 4px;
    margin:8px;
}
.msg-content2 {
    background-color: #ffffff;
    color: #333333;
    border-radius: 4px;
    border: 1px solid #409EFF;
    padding: 4px;
    margin: 8px;
}
</style>