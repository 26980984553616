<template>

    <div class="login">
        <img src="@/assets/logo.png" alt="" class="logo">
        <el-card class="login-main" 
            :body-style="{'width': 'calc(100% - 80px)','padding': '0px 40px 0 40px','height:': '100%'}">
            <div class="name" v-if="sysInfoModel.name">
                {{ sysInfoModel.name}}
            </div>
            <div class="title">
                医匠登录
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="70px">
                <el-form-item label="账号" prop="account">
                    <el-input ref="accountRef" type="text" style="width: 87%" placeholder="请输入帐号" v-model="ruleForm.account" @keyup.enter.native="switchFocus" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input ref="passwordRef" type="password" style="width: 87%" placeholder="请输入密码" v-model="ruleForm.password"  @keyup.enter.native="submitForm" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop="rebme" class="rebme" >
                    <el-checkbox v-model="ruleForm.rebme">记住我</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button :loading="loading" style="width: 87%" type="default" @click="submitForm">
                        {{loading ? '登录中' : '登录'}}
                    </el-button>
                </el-form-item>
            </el-form>
            <div class="version">v.{{ setting.version }}</div>
        </el-card>
        <div class="detail">
            <a _ngcontent-rlx-c50="" v-if="!isMc" target="view_window" style="color: #fff">Copyright © 2023 - 2030 医匠 All Rights Reserved.</a>
            <a _ngcontent-rlx-c50="" v-if="isMc" target="view_window" href="https://beian.miit.gov.cn" style="margin-right: 20px;">晋ICP备17001123号</a>
            <a _ngcontent-rlx-c50="" v-if="isMc" target="view_window" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14082602000062">晋公安网备14082602000062</a>
        </div>
    </div>
</template>

<script>
import { getSysInfo } from '@/services/app.info.service';
import { getToken,getUserInfo } from '../services/user.service';
import { set,get, clear } from '@/utils/local';
import { getSetting } from '@/utils/dic';

export default {
    name: 'LoginView',
    components: {
    },
    data() {
        let url = '';
        if (process.env.NODE_ENV === 'production') {
            url = window.globalConfig.rootUrl;
        } else {
            url = process.env.VUE_APP_BASE_URL;
        }
        return {
            isMc: url ? url.indexOf('magicalconch') != -1 : false,
            loading: false,
            setting: {},
            ruleForm: {
                account: '',
                password: '',
                rebme: false
            },
            sysInfoModel: {
                name: ''
            },
            rules: {
                account: [
                    { required: true, message: '输入账号', trigger: 'blur' },
                    { min: 3, max: 24, message: '长度在 3 到 24 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '输入密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
                rebme: [
                    { required: false, message: '输入密码', trigger: 'blur' }
                ]
            }
        };
    },
    created(){
    },
    mounted(){
        let localUser = get('localuser');
        if (localUser) {
            this.ruleForm = {...localUser};
        }
        this.setting = getSetting();
        getSysInfo(true).then(res=>{
            console.log('getSysInfo' + res)
            this.sysInfoModel.name = res ? res.name : ''; 
        });
    },
    methods: {
        switchFocus() {
            // 使当前input失去焦点
            this.$refs.accountRef.blur();
            // 使input2获得焦点
            this.$refs.passwordRef.focus();
        },
        jumpHome(ures){
            if (ures && ures.isAdmin == true) {
                this.$router.push('/home');
            } else {
                this.$router.push('/index');
            }
        },
        submitForm() {
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                    this.login();
                } else {
                    this.$message.warning('请输入正确的登录信息');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 登录
        login(){
            this.loading = true;
            let params = new URLSearchParams()
            let clientId = 'Api_App';
            if (process.env.NODE_ENV === 'production') {
                clientId = window.globalConfig.clientId;
            }
            params.append('grant_type', 'password')
            params.append('client_id', clientId)
            params.append('password', this.ruleForm.password)
            params.append('username', this.ruleForm.account)
            params.append('scope', 'Api offline_access')
            getToken(params).then(res => {
                set('token', res, res.expires_in);
                // 是否缓存
                if (this.ruleForm.rebme) {
                    set('localuser', this.ruleForm);
                } else {
                    clear('localuser');
                }
                getUserInfo().then(ures =>{
                    set('user', ures, res.expires_in);
                    this.loading = false;
                    this.$message.success('欢迎登录医匠,' + ures.name);
                    this.jumpHome(ures);
                }, () => {
                    this.$message.error('获取用户信息失败');
                    this.loading = false;
                });
            }, () => {
                this.$message.error('登录失败');
                this.loading = false;
            });
        }
    }
}
</script>

<style lang="scss" scoped> 
.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;  
    background-image: url(../assets/yijiang.png);
    background-size:100%;
}
.login-main {
    width: 580px; 
    height: 450px; 
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    position: relative; 
    -moz-box-shadow:2px 2px 5px #333333; -webkit-box-shadow:2px 2px 5px #333333; box-shadow:2px 2px 5px #333333; 
    @keyframes gradient {
        0% {
        background-position: 0% 50%;
        }
        50% {
        background-position: 100% 50%;
        }
        100% {
        background-position: 0% 50%;
        }
    }
}
.el-card__body {
    height: 100% !important;
    width: 100% !important;
    padding: 0;
}
.title {
    width: 100%;
    font-size: 24px;
    height: 75px;
    font-weight: 550;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    color: #409EFF;
}
.name {
    width: 100%;
    font-size: 14px;
    height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #409EFF;
}

.detail {
    position: absolute;
    bottom: 5px;

    a {
        font-size: 10px;
        color: #ffffff;
        text-decoration:none;
    }
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
}
.version {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 10px;
    color: #cccccc;
}

</style>