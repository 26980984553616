

export function formatDate(curr, fmt) {
    if (!curr) {
        return '';
    }
    var date = new Date(curr);
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    }
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
        }
    }
    return fmt;
}

// 是否符合yyyy-MM-ddThh:mm:ss | yyyy-MM-dd hh:mm:ss | yyyy-MM-dd
export function isDate(str) {
    var reg = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    var reg1 = /^\d{4}-\d{2}-\d{2}$/;
    var reg2 = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
    return reg.test(str) | reg1.test(str) | reg2.test(str) | isDateType(str);
}

// 是否为js的日期类型
export function isDateType(value){
    return typeof value === 'object' && value instanceof Date;
}

// 是否为yyyy-MM-dd的日期格式
export function isSimpleDate(str) {
    var reg = /^\d{4}-\d{2}-\d{2}$/;
    return reg.test(str);
}

// 日期格式补0
function padLeftZero(str) {
    return ('00' + str).substr(str.length)
}
