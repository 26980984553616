import request from '@/utils/request'
import authrequest from '@/utils/authrequest'
import {clear,get} from '@/utils/local'

// 获取令牌 - pwdmode
export function getToken(data) {
    return request({
        url: '/connect/token',
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        data
    })
}

// 获取用户信息
export function getUserInfo(){
    return authrequest({
        url: '/api/app/user',
        method: 'get'
    })
}

// 修改用户信息
export function updateUserInfo(data){
    return authrequest({
        url: '/api/app/user',
        method: 'put',
        data
    })
}

// 修改用户密码
export function updateUserPwd(data){
    return authrequest({
        url: '/api/account/my-profile/change-password',
        method: 'post',
        data
    })
}

// 登出-清空缓存
export function clearCache(){
    clear('token');
    clear('user');
}

// 获取本地缓存的用户信息
export function getLocalUser(){
    return get('user');
}