<template>
    <div class="content">
        <el-tabs class="tab" v-model="activeName" style="width: 560px;">
            <el-tab-pane label="资料设置" name="1">
                <el-form :model="userData" :rules="userRules" ref="userData" 
                    label-position="right" label-width="80px">
                    <el-form-item label="账号">
                        <el-input style="width: 190px" v-model="userName" disabled></el-input>
                    </el-form-item>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item label="角色">
                                <el-select v-model="userAppRoles" multiple disabled>
                                    <el-option
                                    v-for="item in appRoleOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="角色">
                            <el-select v-model="userRoles" multiple disabled>
                                <el-option
                                v-for="item in roleOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12">
                            <el-form-item prop="name" label="姓名" required>
                                <el-input style="width: 185px" v-model="userData.name" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item prop="phone" label="手机号码">
                                <el-input style="width: 185px" v-model="userData.phone" placeholder="请输入11位手机号码"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item prop="email" label="邮箱">
                        <el-input style="width: 400px" v-model="userData.email" placeholder="请输入邮箱"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="saveUserloading" @click="updateUserData" type="primary" >保存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="修改密码" name="2">
                <el-form :model="pwdForm" :rules="pwdRules" ref="pwdForm" label-position="right" label-width="110px">
                    <el-form-item prop="currentPassword"  label="原密码" required>
                        <el-input v-model="pwdForm.currentPassword" type="password" placeholder="请输入原密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="newPassword"  label="新密码" required>
                        <el-input v-model="pwdForm.newPassword" type="password" placeholder="请输入新的密码"></el-input>
                    </el-form-item>
                    <el-form-item prop="retryNewPassword"  label="重输新密码" required>
                        <el-input v-model="pwdForm.retryNewPassword" type="password" placeholder="请在此输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="savePwdloading" @click="updateUserPassword" type="primary" >保存</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { getUserInfo, updateUserInfo,updateUserPwd,clearCache } from '@/services/user.service';
import { set,get } from '@/utils/local';

export default {
    name: 'UserInfo',
    comments: {
    },
    props: {
        active: {
            type: String,
            default: '1'
        }
    },
    data(){
        var checkRetryNewPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请再次输入密码'));
            }
            else if (value != this.pwdForm.newPassword) {
                callback(new Error('两次输入密码不一致!'));
            }
            else {
                callback();
            }
        };
        return {
            roleOptions: [{
                label: 'admin',
                value: 'admin'
            },{
                label: '普通用户',
                value:  '普通用户'
            },{
                label: '应用管理员',
                value:  '应用管理员'
            }],
            userName: '',
            userRoles: [],
            userAppRoles: [],
            userData: {
                name: '',
                phone: '',
                email: ''
            },
            activeName: this.active,
            userRules:  {
                name: [
                    { required: true, message: "请输入用户名", trigger: "blur" },
                    { min: 3, max: 24, message: "用户名长度不正确，应为3-24位字符", trigger: "blur" },
                ],
                phone: [
                    { required: true, message: "请输入手机号码", trigger: "blur" },
                    { pattern: /^[0-9]{11}$/, message: "手机号码格式不正确", trigger: "blur" },
                ],
                email: [
                    { validator: (rule, value, callback) => {
                        if (!value || /^\s*$/.test(value) || /^[A-Za-z0-9]*@[A-Za-z0-9]*\.[A-Za-z0-9]{2,}$/.test(value)) {
                            callback();
                        } else {
                            callback(new Error("邮箱格式不正确"));
                        }
                        },
                        trigger: "blur"
                    }
                ]
            },
            pwdForm: {
                currentPassword: '',
                newPassword: '',
                retryNewPassword: ''
            },
            pwdRules: {
                currentPassword: [
                    { required: true, message: '输入原密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '输入新密码', trigger: 'blur' },
                    { min: 6, max: 24, message: '长度在 6 到 24 个字符', trigger: 'blur' }
                ],
                retryNewPassword: [
                    { validator: checkRetryNewPassword, trigger: 'blur' }
                ]
            },
            saveUserloading: false,
            savePwdloading: false
        }
    },
    mounted(){
        let userInfo = get('user');
        if (userInfo) {
            console.dir(userInfo);
            this.userData.name = userInfo.name;
            this.userData.phone = userInfo.phone;
            this.userData.email = userInfo.email;
            this.userRoles = userInfo.roles;
            this.userAppRoles = userInfo.appRoles;
            this.userName = userInfo.userName;
        }
    },
    methods: {
        updateUserData(){
            this.$refs['userData'].validate((valid) => {
                if (valid) {
                    this.saveUserloading = true;
                    updateUserInfo(this.userData).then(() => {
                        getUserInfo().then(ures=>{
                            this.$message.success('更新信息成功');
                            this.saveUserloading = false;
                            set(JSON.stringify(ures.data));
                        })
                    },(err)=>{
                        this.$message.error(err ? err : '请求发生错误');
                        this.saveUserloading = false;
                    })
                } else {
                    this.$message.error('请确认信息填写是否正确');
                    this.savePwdloading = false;
                    return false;
                }
            });
        },
        updateUserPassword(){
            this.$refs['pwdForm'].validate((valid) => {
                if (valid) {
                    this.savePwdloading = true;
                    console.log(this.pwdForm);
                    updateUserPwd(this.pwdForm).then(()=>{
                        this.savePwdloading = false;
                        this.$message.success('密码重置成功，请重新登录');
                        clearCache();
                        this.$router.push('/login');
                    },(err)=>{
                        this.$message.error(err ? err : '请求发生错误');
                        this.savePwdloading = false;
                        return false;
                    })
                } else {
                    this.$message.error('请确认信息填写是否正确');
                    this.savePwdloading = false;
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    width: 570px;
    max-height: 380px;
    display: flex;
    flex-direction: column;
    padding: 0;

    .tab {
        width: 100%;
        height: 100%;
    }
}
</style>