
import authrequest from '@/utils/authrequest'

const key = "yijiangsysinfo";

// 获取配置
export function getSysInfo(iscache){ 
    console.log('getSysInfo')
    if (iscache) {
        let cache = localStorage.getItem(key);
        if (cache) {
            return new Promise((resolve)=>{
                resolve(JSON.parse(cache))
            });
        } else {
            return get();
        }
    } else {
        return get();
    }
}

function get(){
    return new Promise((resolve, reject)=>{
        authrequest({
            url: '/api/app/app-register/info',
            method: 'get'
        }).then(res=>{
            localStorage.setItem(key, JSON.stringify(res));
            resolve(res)
        }, err=>{
            reject(err)
        })
    });
}

// 获取配置
export function updateSysInfo(data){
    return new Promise((resolve, reject)=>{
        authrequest({
            url: '/api/app/app-register/info',
            method: 'put',
            data
        }).then(res=>{
            localStorage.setItem(key, '');
            resolve(res)
        }, err=>{
            reject(err)
        })
    });
}