
// 数据库类型
export function getDbType(){
    return [{
        name: 'Mysql',
        value: 1
    },{
        name: 'SqlServer',
        value: 2
    },{
        name: 'Oracle',
        value: 3
    },{
        name: 'PostgreSQL',
        value: 4
    }];
}

// 默认配置
export function getSetting(){
    return {
        version: process.env.VERSION,
        name: '内部测试版',
        date: '2099-08-04'
    }
}

// 环境变量字典
export const envOptions = [{
    label: '当前登陆人',
    value: 0,
    items: [{
        label: '姓名',
        value: 0
    }, {
        label: '部门',
        value: 1
    },{
        label: '账号',
        value: 2
    },{
        label: '手机号码',
        value: 3
    },{
        label: '角色',
        value: 5
    }]
    },{
        label: '当前环境',
        value: 1,
        items: [{
            label: '当前时间',
            value: 4
        }]
    }
]

// 计算公式字典
export const calcOption = [{
        label: '等于',
        value: 0
    },
    {
        label: '包含',
        value: 1
    },
    {
        label: '不等于',
        value: 2
    },
    {
        label: '不包含',
        value: 3
    }
]