<template>
  <div class="content">
    <div class="title">
      <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
          <el-menu-item  @click="switchCollapse"  style="width: 44px">
            <img src="@/assets/logo.png" alt="" style="width: 25px; height: 25px;">
          </el-menu-item>
          <el-menu-item>
            <span style="font-weight: 600; color: #409EFF;font-size: 22px">医匠设计</span>
          </el-menu-item>
          <el-menu-item>
            <div class="menu-line"></div>
            <el-button @click="switchMode" type="text">前往应用</el-button>
          </el-menu-item>
          <el-menu-item v-if="sysInfoModel.name">
            <div class="menu-line"></div>
            <span style="color: #409EFF;font-size: 14px">{{sysInfoModel.name}}</span>
          </el-menu-item>
          <el-submenu class="user" index="2">
            <template slot="title">{{userData.name}}</template>
            <el-menu-item @click="showUserInfo" index="2-1">我的资料</el-menu-item>
            <el-menu-item @click="logout" index="2-3">退出登录</el-menu-item>
          </el-submenu>
      </el-menu>
    </div>
    <div class="body">
      <el-menu
          default-active="0"
          :collapse="isCollapse"
          class="el-menu-vertical-demo">
          <el-menu-item index="0"  @click="jumpRouter('/home')">
            <i class="el-icon-s-home"></i>
            <span slot="title">主页</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-orange"></i>
              <span>应用表单</span>
            </template>
            <!-- <el-menu-item index="1-1" @click="jumpRouter('/home/form-list')">
              <i class="el-icon-tickets"></i><span>我的表单</span>
            </el-menu-item> -->
            <el-menu-item index="1-2" @click="jumpRouter('/home/app')">
              <i class="el-icon-menu"></i><span>我的应用</span>
            </el-menu-item>
            <el-menu-item index="1-3" @click="jumpRouter('/home/datasource')">
              <i class="el-icon-link"></i><span>我的数据源</span>
            </el-menu-item>
            <el-menu-item index="1-4" @click="jumpRouter('/home/dic')">
              <i class="el-icon-notebook-1"></i><span>我的字典</span>
            </el-menu-item>
          </el-submenu>
          <!-- <el-menu-item index="2" @click="jumpRouter('/home/store')">
            <i class="el-icon-goods"></i>
            <span slot="title">应用市场</span>
          </el-menu-item> -->
          <el-menu-item index="7" @click="jumpRouter('/home/report-design')">
              <i class="el-icon-s-order"></i><span>打印报表</span>
            </el-menu-item>
          <el-menu-item index="3" @click="jumpRouter('/home/report')">
            <i class="el-icon-s-data"></i>
            <span slot="title">报表中心</span>
          </el-menu-item>
          <el-menu-item index="8" @click="jumpRouter('/home/work')">
            <i class="el-icon-s-data"></i>
            <span slot="title">后台作业</span>
          </el-menu-item>
          <el-menu-item index="4" @click="jumpRouter('/home/org-struct')" v-if="userData && userData.roles.includes && userData.roles.includes('admin')">
            <i class="el-icon-s-custom"></i>
            <span slot="title">组织架构</span>  
          </el-menu-item>
          <el-menu-item index="5" @click="jumpRouter('/home/authconfig')" v-if="userData && userData.roles.includes && userData.roles.includes('admin')">
            <i class="el-icon-s-check"></i>
            <span slot="title">角色权限</span>
          </el-menu-item>
          <el-menu-item index="6" @click="jumpRouter('/home/setting')" v-if="userData && userData.roles.includes && userData.roles.includes('admin')">
            <i class="el-icon-setting"></i>
            <span slot="title">系统设置</span>
          </el-menu-item>
      </el-menu>
      <div :class="isCollapse ? 'main max-main': 'main min-main'">
        <router-view />
      </div>
    </div>
    <el-dialog  
        title="用户资料"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :visible.sync="userInfoVisible">
        <div>
            <user-info  />
        </div>
    </el-dialog>  
  </div>
</template>

<script>
import { getSysInfo } from '@/services/app.info.service';
import UserInfo from '@/components/users/UserInfo.vue';
import { clearCache, getLocalUser } from '@/services/user.service';
export default {
  name: 'HomeView',
  components: {
    UserInfo
  },
  data() {
      return {
        sysInfoModel: {
          name: ''
        },
        userData: {
          name: '',
          phone: '',
          email: '',
          roles: []
        },
        userInfoVisible: false,
        activeIndex: '1',
        isCollapse: false,
        menuList: [{
          title: '血透耗材上报',
          id: '111',
          isSub: true,
          submenu: [{
            title: '耗材信息上报',
            id: '111',
          },{
            title: '耗材归档',
            id: '111',
          }]
        }, {
          title: '人员绩效加班',
          id: '22',
          isSub: false
        }, {
          title: '科室质量管控',
          id: '22',
          isSub: false
        }, {
          title: '检验数据上报',
          id: '22',
          isSub: false
        }, {
          title: '耗材损益上报',
          id: '22',
          isSub: false
        }]
      };
    },
    mounted(){
      this.userData = getLocalUser();
      getSysInfo(true).then(res=>{
          console.log('getSysInfo' + res)
          this.sysInfoModel.name = res ? res.name : ''; 
      });
    },
    methods: {
      showUserInfo(){
        this.userInfoVisible = true;
      },
      jumpApp(){
        this.$router.push('/index/app');
      },
      switchMode(){
        this.getAppMenu();
      }, 
      // 获取当前角色，有权限访问的应用菜单列表
      getAppMenu(){
        this.$router.push('/index');
      },
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      switchCollapse(){
        this.isCollapse = !this.isCollapse;
      },
      logout(){
        clearCache();
        this.$router.push('/login');
      },
      jumpRouter(router){
        this.$router.push(router);
      }
    }
}
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
}
.el-menu-vertical-demo {
  height: 100%;
}
.content {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.title {
  width: 100%;
  height: 60px;
  padding: 0;
  margin-bottom: 1px;
}
.menu-line {
  border-left: 1px solid #ccc; 
  height: 40%; 
  width: 2px; 
  position: absolute; 
  left: 0; 
  top: 20px;
}
.switchmode {
  position: absolute;
  right: 5px;
}
.user {
  position: absolute;
  right: 5px;
}
.body {
  max-height: calc(100vh - 45px);
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
}
.menu {
  height: 100%;
  transition: all 0.8s;
  background: #545c64;
}

.main {
  flex: 1;
  height: 100%;
}
.min-main {
  max-width: calc(100% - 240px);
}
.max-main {
  max-width: calc(100% - 64px);
}
.el-dialog__body {
  padding: 0 20px;
}
</style>
