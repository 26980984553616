import authrequest from '@/utils/authrequest'

export function generator(data){
    return authrequest({
        url: '/api/app/ai/generator',
        method: 'post',
        data
    })
}

export function chat(data){
    return authrequest({
        url: '/api/app/ai/chat',
        method: 'post',
        data
    })
}