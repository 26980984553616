<template>
    <div class="content">
        <img src="@/assets/logo.png" alt="" class="logo">
        <el-form ref="form"
            class="form"
            label-width="80px"
            label-position="top"
            :model="formData"
            :rules="formRules">
            <div class="heard">{{ notRegister ? '医匠软件激活' : '医匠软件续期'}}</div>
            <el-row style="margin: 100px 35px 0px 35px;">
                <el-form-item label="本机识别码">
                    <el-input readonly type="text" v-model="uid" placeholder="请输入激活码"></el-input>
                    <el-button type="text" style="position: absolute; top: -50px; left: 350px"
                    @click="copy">复制识别码</el-button>
                </el-form-item>
            </el-row>
            <el-row style="margin: -10px 35px 10px 35px;">
                <el-form-item prop="no" label="激活码">
                    <el-input type="textarea" rows="3" v-model="formData.no" placeholder="请输入您的激活码"></el-input>
                </el-form-item>
            </el-row>
            <el-row  style="margin: 0 35px 0 35px;">
                <el-button :loading="loading" @click="register" type="primary" style="width: 100%;">提交</el-button>
            </el-row>
            <el-button v-if="notRegister" type="text" class="login" @click="login">返回登录</el-button>
            <el-button v-if="!notRegister" type="text" class="login" @click="goSetting">返回设置</el-button>
            <div class="detail">{{ notRegister ? '您的软件已过有效期，请联系软件管理员激活后使用' : 
                settingLoading ? '正在加载激活信息...' : '当前软件有效期剩余：' + systemSettingModel.day + ' 天，续期后天数将累加，感谢支持'}}
            </div>
        </el-form>
    </div>
</template>
<script>
import { appRegister, getUid } from '@/services/app.service';
import { getLocalUser } from '@/services/user.service';
import { getRegister } from '@/services/app.service';

export default {
    name: 'AppRegister',
    props: {
        config: []
    },
    created(){
    },
    mounted(){ 
        this.getUidInfo();
        this.user = getLocalUser();
        this.notRegister = true;
        this.settingLoading = true;
        getRegister().then(res=>{
            this.systemSettingModel = res;
            this.notRegister = res.day <= 0;
            this.systemSettingModel.version = process.env.VERSION;
            this.settingLoading = false;
        },()=>{
            this.settingLoading = false;
        });
    },
    data(){
        return {
            settingLoading: false,
            systemSettingModel: {},
            notRegister: false,
            user: {},
            formData: {
                no: ''
            },
            formRules: {
                no: [{ required: true, message: '请输入激活码', trigger: 'blur' },
                    { min: 1, max: 10240, message: '激活码长度在 1 到 10240 个字符', trigger: 'blur' }]
            },
            loading: false,
            uid: '正在加载...'
        };
    },
    methods: {
        goSetting(){
            this.$router.push('/home/setting');
        },
        getUidInfo(){
            getUid().then(res=>{
                this.uid = res;
            });
        },
        copy(){
            navigator.clipboard.writeText(this.uid).then(() => {
                this.$message.success('文本复制成功');
            }).catch(() => {
                this.$message.error('文本复制失败');
            });
        },
        login(){
            this.$router.push('/login');
        },
        register(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    appRegister(this.formData.no).then(()=>{
                        if (this.notRegister) {
                            this.$message.success('注册成功');
                            this.loading = false;
                            this.login();
                        } else {
                            this.$message.success('续期成功');
                            this.goSetting();
                        }
                    },()=>{
                        this.loading = false;
                    })
                } else {
                    this.$message.warning('请输入正确的登录信息');
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    background: #56CCF2;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2F80ED, #56CCF2);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2F80ED, #56CCF2); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    .form {
        width: 500px;
        height: 470px;
        background: #fff;
        border-radius: 10px;
        padding: 10px 35px;
        position: relative;

        .uid {
            width: 200px;
            position: absolute;
            top: 120px;
            left: 70px;
            
        }

        .heard {
            position: absolute;
            top: 55px;
            font-weight: 600px;
            font-size: 28px;
            width: calc(100% - 70px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .detail {
            position: absolute;
            bottom: -23px;
            left: 22%;
            font-size: 12px;
            color: #fff;
        }
        .login {
            position: absolute;
            bottom: 5px;
            right: 20px;
        }
    }
}
.logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
}

</style>