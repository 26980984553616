
import { getFormDataList,getFormDataJoinList } from '@/services/app.service';
import { getLocalUser } from '@/services/user.service';
import { formatDate } from '@/filters/dateformat';
import { getGuid } from '@/utils/id';
const minWidth = 90;
// 表格解析列宽算法
export function getColumnAutoWidth(item){
    if (item.width && item.width > 0) {
        return item.width;
    }
    let typeWidth = 130;
    if (item.type == 'date') {
        typeWidth = 150;
    }
    if (item.type == 'datetime') {
        typeWidth = 200;
    }
    if (item.type == 'textarea') {
        typeWidth = 300;
    }
    let dft = 30 + (item.name.length * 20);
    let nameWidth = dft < 301 ? dft : 300;
    if (typeWidth > nameWidth) {
        return typeWidth;
    } else {
        return nameWidth;
    }
}

// 自动列宽计算
export function getMaxLength(objCollection, name, dataName){
    let maxLength = 0;
    // 只取前20数据最大长度
    let length = objCollection.length > 20 ? 20 : objCollection.length;
    for (let i = 0; i < length; i++) {
        // 获取当前对象的name属性长度
        let nameLength = objCollection[i][dataName] ? getByteLength(objCollection[i][dataName]) : 0;
        
        // 如果当前长度大于最大长度，则更新最大长度
        if (nameLength > maxLength) {
            maxLength = nameLength;
        }
    }
    let val = minWidth;
    let isName = maxLength < getByteLength(name);
    if (isName) {
        val = name.length * 11;
    } else {   
        val = maxLength * 11;
    }
    // console.log('getMaxLength:' + name  + '|(' + (isName ? 'name.length' : 'maxLength') + ')' + (val < minWidth ? minWidth : val));
    return val < minWidth ? minWidth : val; 
}

function getByteLength(str) {
    const encoder = new TextEncoder();
    const encodedStr = encoder.encode(str);
    return encodedStr.length;
}

// 关联只读查询
export function getAppReadList(page, tableData, pageData, configData){
    let SkipCount = 0;
    if (page){
        SkipCount = page * pageData.pageSize;
    }
    let props = {
        id: configData.readDataSource.id,
        table: configData.readDataSource.table,
        column: configData.readDataSource.column,
        MaxResultCount: pageData.pageSize,
        SkipCount: SkipCount
    };
    return new Promise((resolve, reject) => {
        getFormDataList(props).then(rres=>{
            let rTableData = rres.items;
            // 如果存在数据
            if (rTableData.length > 0) {
                // 查询关联数据
                let dataList = rTableData.filter(c=> c[configData.readDataSource.column]);
                if (dataList.length < 1) {
                    pageData.loading = false;
                    pageData.totalCount = rres.totalCount;
                    tableData = rTableData;
                }
                getFormDataJoinList({
                    id: configData.dataSource.id,
                    table: configData.dataSource.table,
                    column: configData.dataSource.column,
                    data: dataList.map(c=> c[configData.readDataSource.column].toString()),
                    dicModels: configData.dicConfigList
                }).then(wres=>{
                    let wTableData = wres.items;
                    let mainData = rTableData;
                    let joinData = wTableData;
                    // 合并关联数据
                    tableData = mergeArrays(mainData, joinData, 
                        configData.readDataSource.column,
                        configData.dataSource.column);

                    resolve({
                        tableData, 
                        totalCount: rres.totalCount
                    });
                },(err) => {
                    reject(err);
                });
            } else {
                resolve({
                    rTableData, 
                    totalCount: rres.totalCount
                });
            }
        },(err)=>{
            reject(err);
        });
    })
}

// 获取列表数据
export function getAppList(page, id, tableData, pageData, queryModel, configData, joinWhere = ''){
    let searchData = getSearchList(queryModel,configData);
    // 如果没有搜索条件，且存在只读数据源，获取只读数据源列表匹配写数据源
    if (configData.readDataSource && searchData.findIndex(c=> c.value) == -1) {
        return getAppReadList(page, tableData, pageData, configData);
    }
    let SkipCount = (pageData.currentPage - 1) * pageData.pageSize
    let prop = {
        id: configData.dataSource.id,
        formId: id,
        table: configData.dataSource.table,
        column:  configData.dataSource.column,
        MaxResultCount: pageData.pageSize,
        SkipCount: SkipCount,
        SearchData : searchData,
        dicModels: configData.dicConfigList,
        joinWhere: joinWhere
    };
    return new Promise((resolve, reject) => {
        getFormDataList(prop).then(res=>{
            if (res.totalCount > 0 && configData.readDataSource) {
                let dataList = res.items.filter(c=> c[configData.dataSource.column]);
                if (dataList.length < 1) {
                    tableData = res.items;
                    pageData.loading = false;
                    pageData.totalCount = res.totalCount;
                }
                getFormDataJoinList({
                    id: configData.readDataSource.id,
                    table: configData.readDataSource.table,
                    column: configData.readDataSource.column,
                    data: dataList.map(c=> c[configData.dataSource.column].toString()),
                    dicModels: configData.dicConfigList
                }).then(rres => {
                    // 合并关联数据  
                    tableData = mergeArrays(res.items, rres.items, 
                        configData.dataSource.column,
                        configData.readDataSource.column);
                    resolve({
                        tableData, 
                        totalCount: res.totalCount
                    });
                },(err) => {
                    reject(err);
                })
            } else {
                tableData = res.items;        
                resolve({
                    tableData, 
                    totalCount: res.totalCount
                });
            }
        },(err) => {
            reject(err);
        });
    });
}

// 合并对象数组
function mergeArrays(arr1, arr2, key1, key2) {
    if (!arr2 || arr2.length === 0) {
        return arr1;
    }

    const mergedArray = [];

    for (let i = 0; i < arr1.length; i++) {
        const objFromArray1 = arr1[i];
        const correspondingObjInArr2 = arr2.find(obj => obj[key2] === objFromArray1[key1]);

        let mergedObj = Object.assign({}, objFromArray1);
        mergedObj.rid = objFromArray1[key1];
        mergedObj.wid = correspondingObjInArr2 ? correspondingObjInArr2[key2] : undefined;
        mergedObj = Object.assign(mergedObj, correspondingObjInArr2);

        if (!correspondingObjInArr2) {
        for (let key in arr2[0]) {
            if (!Object.prototype.hasOwnProperty.call(mergedObj, key)) {
            mergedObj[key] = undefined;
            }
        }
        }
        mergedArray.push(mergedObj);
    }

    return mergedArray;
}

// 拼接查询条件
function getSearchList(queryModel, configData){
    let searchData = [];
    for (const key in queryModel) {
        const data = queryModel[key];
        let currConfig = configData.queryConfigList.find(c=> c.dataName == key);
        searchData.push({
        name: key,
        type: currConfig ? currConfig.type : 'text',
        value: data ? currConfig && (currConfig.type == 'date' || currConfig.type == 'datetime') ? data.join(',') : data : ''
        })
    }
    return searchData;
}

// 下载文件
export function downloadAppFile(data, fileName) {
    const blob = new Blob([data]); 
    const elink = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
}

// 提取sql模版变量
export function extractValues(str) {
    const regex = /{{(.*?)}}/g;
    const matches = str.match(regex);
    const values = matches.map(match => match.replace("{{", "").replace("}}", "").trim());
    return values;
}

// 提取sql模版变量
export function extractValues1(str) {
    const regex = /\[(.*?)\]/g;
    const matches = str.match(regex);
    const values = matches.map(match => match.replace("[", "").replace("]", "").trim());
    return values;
}

export function getSysValue(column){
    let user = getLocalUser();
    if (column == '当前时间') {
        return formatDate(new Date(),'yyyy-MM-dd hh:mm:ss');
    } else if (column == '当前时间戳') {
        return Date.parse(new Date());
    }  else if (column == '当前登陆人姓名') {
        return user.name;
    } else if (column == '当前登陆人手机') {
        return user.phone;
    } else if (column == '当前登陆人账号'){
        return user.userName;
    } else if (column == '当前登陆人部门'){
        return user.depts && user.depts.length > 0 ? user.depts[0] : '';
    } else if (column == '当前登陆人角色'){
        return user.appRoles && user.appRoles.length > 0 ? user.appRoles[0] : '';
    } else if (column == 'guid'){
        return getGuid();
    } else {
        return '';
    }
}

export function getEnvValue(source, column, row = null){
    // 当前登陆人
    if (source == 0) {
        let user = getLocalUser();
        if (column == 0) {
            return user.name;
        } else if (column == 3) {
            return user.phone;
        } else if (column == 2){
            return user.userName;
        } else if (column == 1){
            return user.depts && user.depts.length > 0 ? user.depts[0] : '';
        } else if (column == 5){
            return user.appRoles && user.appRoles.length > 0 ? user.appRoles : '';
        }
    }
    // 当前环境
    if (source == 1) {
        // 当前时间
        if (column == 4) {
            return formatDate(new Date(),'yyyy-MM-dd hh:mm:ss');
        }
    }
    // 当前行数据
    if (source == 2) {
        // 优先获取枚举值，如果没有，返回列值
        let enumName = column + '&Value';
        return row[enumName] != null && row[enumName] != undefined ? row[enumName] : row[column];
    }
    return '';
}