<template>
    <div class="content">
        <img src="@/assets/logo.png" alt="" class="logo">
        <el-form ref="form"
            class="form"
            label-width="100px"
            label-position="right"
            :model="formData"
            :rules="formRules">
            <div class="heard">
                医匠激活码生成
            </div>
            <el-form-item prop="id" label="识别码">
                <el-input type="textarea" rows="2" v-model="formData.id" placeholder="请输入机器识别码"></el-input>
            </el-form-item>
            <el-form-item prop="day" label="激活天数">
                <el-input-number v-model="formData.day" @change="handleChange" :min="1" :max="99999" placeholder="输入天数"></el-input-number>
            </el-form-item>
            <el-form-item prop="endDate" label="截止日期">
                <el-date-picker
                v-model="formData.endDate"
                type="date"
                placeholder="选择有效期截止日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item prop="version" label="激活版本">
                <el-radio-group v-model="formData.version">
                    <el-radio :label="0">普通版</el-radio>
                    <el-radio :label="1" disabled>高级版</el-radio>
                    <el-radio :label="2" disabled>测试版</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-row  style="margin: 30px 35px 0 35px;">
                <el-button :loading="loading" @click="submitGetKey" type="primary" style="width: 100%;">生成激活码</el-button>
            </el-row>
            <el-button type="text" class="login" @click="goSetting">返回设置</el-button>
        </el-form>
        <el-dialog
            title="生成成功"
            :visible.sync="dialogVisible"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="30%">
            <div>
                <div>
                    <el-button style="float: right;" type="text" @click="copy">复制识别码</el-button>
                </div>
                <div>
                    <el-input type="textarea" rows="9" v-model="key" placeholder="请输入机器识别码"></el-input>
                </div>

            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { getKey, getUid } from '@/services/app.service';

export default {
    name: 'AppRegister',
    props: {
        config: []
    },
    created(){
    },
    data(){
        const getDftDate = () => {
            // 获取当前日期
            let currentDate = new Date();

            // 设置指定天数后的日期（这里设置7天后的日期）
            currentDate.setDate(currentDate.getDate() + 90);
            return currentDate;
        };
        return {
            formData: {
                day: 90,
                version: 0,
                id: '',
                endDate: getDftDate()
            },
            formRules: {
                day: [{ required: true, message: '请输入激活天数', trigger: 'blur' }],
                version: [{ required: true, message: '请选择激活版本', trigger: 'blur' }],
                id: [{ required: true, message: '请输入识别码', trigger: 'blur' },
                { min: 1, max: 10240, message: '识别码长度在 1 到 10240 个字符', trigger: 'blur' }],
                endDate: []
            },
            loading: false,
            dialogVisible: false,
            id: '',
            key: ''
        };
    },
    mounted(){
        this.getUidInfo();
    },
    methods: {     
        getUidInfo(){
            getUid().then(res=>{
                this.id = res;
            });
        },
        copy(){
            navigator.clipboard.writeText(this.key).then(() => {
                this.$message.success('文本复制成功');
            }).catch(() => {
                this.$message.error('文本复制失败');
            });
        },
        goSetting(){
            this.$router.push('/home/setting');
        },
        submitGetKey(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    getKey(this.formData).then((res)=>{
                        this.key = res;
                        this.loading = false;
                        this.dialogVisible = true;
                    },()=>{
                        this.loading = false;
                    })
                } else {
                    return false;
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.content {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    background-color: #409EFF;

    .form {
        width: 600px;
        height: 500px;
        background: #fff;
        border-radius: 10px;
        padding: 20px 35px;
        position: relative;

        .uid {
            width: 200px;
            position: absolute;
            top: 120px;
            left: 70px;
            
        }

        .heard {
            font-weight: 600px;
            font-size: 28px;
            width: 100%;
            height: 80px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .detail {
            position: absolute;
            bottom: -23px;
            left: 22%;
            font-size: 12px;
            color: #fff;
        }
        .login {
            position: absolute;
            bottom: 5px;
            right: 20px;
        }
    }
}
.logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
}

</style>